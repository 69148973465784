<template>
  <div class="ComboboxEmpty">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ComboboxEmpty {
  display: flex;
  align-items: center;
  padding-block: 16px;
  color: var(--gray-3);
  padding-left: 24px;
}
</style>
