<template>
  <div ref="comboboxRef" class="Combobox" @click="focusOnInput()">
    <div ref="comboboxSearchRef" class="ComboboxSearch">
      <ComboboxSearchIcon />

      <div class="ComboboxSearchContent">
        <template v-if="isMultiple">
          <div v-for="(option, index) in modelValue" :key="index" style="overflow: hidden">
            <slot
              name="selected-option"
              :item="option"
              :index="index"
              :unselect="unselect"
              :is-selected-option-removable="isSelectedOptionRemovable"
            >
              <!-- The #selected-option slot allows for the customization of the selected options -->

              <!-- -------- -->
              <!-- Default: -->
              <!-- -------- -->
              <SelectTag>
                {{ label(option) }}
                <SelectTagRemove v-if="isSelectedOptionRemovable" @click="unselect(option)" />
              </SelectTag>
            </slot>
          </div>
        </template>

        <ComboboxSearchInput
          ref="comboboxInputRef"
          v-model="localSearchTerm"
          :placeholder="isMultiple ? (modelValue.length ? undefined : placeholder) : placeholder"
          :style="{ maxWidth: `${searchRect.width.value - 48}px` }"
        />
      </div>
    </div>

    <div ref="comboboxHeaderRef">
      <slot name="header"></slot>
    </div>

    <div ref="comboboxViewportRef" class="ComboboxViewport">
      <template v-if="optionsToDisplay.length">
        <div
          v-for="(option, index) in optionsToDisplay"
          :key="index"
          :data-index="index"
          @click="toggleSelect(option)"
          @pointermove="activeIndex = index"
        >
          <slot
            name="option"
            :item="option"
            :index="index"
            :is-selected="isSelected(option)"
            :is-active="isActive(index)"
          >
            <!-- The #option slot allows for the customization of the listed options. -->

            <!-- -------- -->
            <!-- Default: -->
            <!-- -------- -->

            <ComboboxItem :is-active="isActive(index)">
              <ComboboxItemIndicator v-if="isSelected(option)" />
              {{ label(option) }}
            </ComboboxItem>
          </slot>
        </div>
      </template>

      <!-- Empty state slot -->
      <slot v-else name="empty" :search-term="searchTerm">
        <ComboboxEmpty>
          Pas de résultats
          <template v-if="searchTerm"> pour "{{ searchTerm }}" </template>
        </ComboboxEmpty>
      </slot>
    </div>

    <div ref="comboboxFooterRef">
      <slot name="footer" :search-term="searchTerm" :select="select">
        <!-- The #footer slot allows for the customization of the footer area. -->

        <!-- -------- -->
        <!-- Example: -->
        <!-- -------- -->

        <!-- <template #footer>
        <ComboboxFooter >
          <Button>
            Create new item
          </Button>
        </ComboboxFooter>
        </template> -->
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { get, isNumber, last, max, min } from "lodash-es";
import { isin } from "~/utils/select";
import { removeAccents } from "@asap/shared/src/utils/string";
import { syncRef } from "@vueuse/core";
import Fuse from "fuse.js";

export type Props<T> = {
  /**
   * The list of selected options.
   */
  modelValue: T[];

  /**
   * The list of selectable options.
   */
  options?: T[];

  /**
   * Determines if multiple selections are allowed.
   */
  isMultiple?: boolean;

  /**
   * Determines if options should be hidden when selected.
   * Defaults to `true` when `isMultiple` is true.
   */
  hideOptionWhenSelected?: boolean;

  /**
   * The placeholder text for the combobox input.
   */
  placeholder?: string;

  /**
   * Determines if the selected options can be removed.
   * Defaults to `true` when `isMultiple` is true.
   */
  isSelectedOptionRemovable?: boolean;

  /**
   * The key path to use for the selected options.
   */
  keyPath?: string;

  /**
   * The label to use for the selected options.
   */
  label?: (option: T) => string;

  /**
   * The search filter to use for the combobox.
   */
  searchFilter?: (options: T[], searchTerm: string) => T[];

  /**
   * The search term to use for the combobox.
   */
  searchTerm?: string;
};

export type Emits<T> = {
  /** Event handler called when the modelValue changes. */
  "update:modelValue": [value: T[]];
  /** Event handler called when the searchTerm changes. */
  "update:searchTerm": [value: string];
  /** Event handler called when an option is selected. */
  onSelect: [option: T];
  /** Event handler called when an option is unselected. */
  onUnselect: [option: T];
};

const props = withDefaults(defineProps<Props<T>>(), {
  options: () => [],
  hideOptionWhenSelected: (props) => !!props.isMultiple,
  isSelectedOptionRemovable: (props) => !!props.isMultiple,
  placeholder: "Rechercher",
  searchTerm: "",
  keyPath: "id",
  label: (option: T) => (typeof option === "object" ? JSON.stringify(option) : String(option)),
  searchFilter: undefined,
});

const emit = defineEmits<Emits<T>>();

const { modelValue, searchTerm } = useVModels(props, emit);

const localSearchTerm = ref(searchTerm.value);

/**
 * Use syncRef to create a two-way binding between the prop and local state
 * This is useful when the prop is passed from a parent component and we want to keep a local copy of it
 *
 * See: https://vueuse.org/shared/syncRef/
 */
syncRef(searchTerm, localSearchTerm);

const comboboxRef = ref<HTMLElement>();
const comboboxSearchRef = ref<HTMLElement>();
const comboboxHeaderRef = ref<HTMLElement>();
const comboboxViewportRef = ref<HTMLElement>();
const comboboxFooterRef = ref<HTMLElement>();

const comboboxRect = useElementBounding(comboboxRef);
const searchRect = useElementBounding(comboboxSearchRef);
const headerRect = useElementBounding(comboboxHeaderRef);
const footerRect = useElementBounding(comboboxFooterRef);

const windowRect = useWindowSize();

// Dynamically set the available height for the super combobox viewport based on window and element dimensions
watchEffect(() => {
  if (comboboxRef.value && comboboxSearchRef.value && comboboxHeaderRef.value && comboboxViewportRef.value) {
    // Using comboboxRect.top as a reactive value instead of `comboboxRef.value.getBoundingClientRect().top`
    // fixes the issue of calculating the available height when Combobox is used in a Popover
    const availableHeight =
      windowRect.height.value -
      comboboxRect.top.value -
      searchRect.height.value -
      headerRect.height.value -
      footerRect.height.value;

    comboboxViewportRef.value.style.setProperty("--combobox-content-available-height", `${availableHeight}px`);
  }
});

const comboboxInputRef = ref();
const inputRef = computed(() => comboboxInputRef.value?.input);
const { focused } = useFocus(inputRef);

const focusOnInput = function () {
  focused.value = true;
};

const { options } = toRefs(props);

const defaultSearchFilter = function (options: T[], searchTerm: string) {
  if (!searchTerm) return options;

  const optionsLabel = options.map((option) => removeAccents(props.label(option)));

  const fuse = new Fuse(optionsLabel, {
    threshold: 0.3,
  });

  return fuse.search(removeAccents(searchTerm)).map((result) => options[result.refIndex]);
};

const optionsToDisplay = computed(() => {
  const availableOptions = props.hideOptionWhenSelected
    ? options.value.filter((option) => !isin(modelValue.value, option, props.keyPath))
    : options.value;

  return props.searchFilter
    ? props.searchFilter(availableOptions, localSearchTerm.value)
    : defaultSearchFilter(availableOptions, localSearchTerm.value);
});

const scrollTo = (index: number) => {
  if (comboboxViewportRef.value) {
    const optionElement = comboboxViewportRef.value.querySelector(`[data-index="${index}"]`) as HTMLElement;

    if (optionElement) {
      const viewportTop = comboboxViewportRef.value.offsetTop;
      const viewportHeight = comboboxViewportRef.value.offsetHeight;

      const optionElementTop = optionElement.offsetTop;
      const optionElementHeight = optionElement.offsetHeight;

      const scrollTop = optionElementTop - viewportTop - (viewportHeight - optionElementHeight) / 2;

      comboboxViewportRef.value.scrollTo({ top: scrollTop });
    }
  }
};

const { index: activeIndex, next, prev } = useCycleList(optionsToDisplay);

const navDirection = ref<"up" | "down">("down");

const haveOptionsBeenInitiatedOnce = ref(false);

watchEffect(() => {
  if (haveOptionsBeenInitiatedOnce.value) return;
  if (options.value.length) haveOptionsBeenInitiatedOnce.value = true;
});

whenever(
  haveOptionsBeenInitiatedOnce,
  () => {
    if (!props.isMultiple && modelValue.value.length) {
      // activeIndex.value = optionsToDisplay.value.findIndex((option) => isEqual(option, modelValue.value[0]));
      activeIndex.value = optionsToDisplay.value.findIndex((option) =>
        props.keyPath
          ? get(option, props.keyPath) === get(modelValue.value[0], props.keyPath)
          : option === modelValue.value[0]
      );

      nextTick(() => {
        scrollTo(activeIndex.value);
      });
    }
  },
  { immediate: true }
);

// Handle ArrowDown key: set direction, move to next, and scroll
useKeyStroke(
  "ArrowDown",
  (e) => {
    e.preventDefault();
    navDirection.value = "down";
    next();
    scrollTo(activeIndex.value);
  },
  { target: comboboxRef }
);

// Handle ArrowUp key: set direction, move to previous, and scroll
useKeyStroke(
  "ArrowUp",
  (e) => {
    e.preventDefault();
    navDirection.value = "up";
    prev();
    scrollTo(activeIndex.value);
  },
  { target: comboboxRef }
);

// Handle Enter key: select option if valid
useKeyStroke(
  "Enter",
  (e) => {
    e.preventDefault();

    if (isNumber(activeIndex.value)) {
      const option = optionsToDisplay.value[activeIndex.value];
      if (option) toggleSelect(option);
    }
  },
  { target: comboboxRef }
);

// If the search term is empty, it removes the last element from the modelValue array.
useKeyStroke(
  "Backspace",
  () => {
    // If the option is not removable, do nothing
    if (!props.isSelectedOptionRemovable) return;

    // If the search term is not empty, do nothing
    if (localSearchTerm.value) return;

    const lastItem = last(modelValue.value);
    if (lastItem === undefined) return;
    if (lastItem === null) return;
    unselect(lastItem);
  },
  { target: comboboxRef }
);

/**
 * Selects an option and adds it to the modelValue array.
 * It also sets the focus back to the search input and adjust the UI
 * Then, it updates the UI in the next DOM update flush to scroll to the next element.
 * @param {T} option - The option to be selected.
 */
const select = function (option: T) {
  if (props.isMultiple) modelValue.value = [...modelValue.value, option];
  else modelValue.value = [option];

  emit("onSelect", option);

  focusOnInput();

  const index = activeIndex.value;

  nextTick(() => {
    const nextActiveIndex =
      navDirection.value === "down" ? min([index, optionsToDisplay.value.length - 1]) : max([0, index - 1]);

    if (nextActiveIndex !== undefined) activeIndex.value = nextActiveIndex;

    scrollTo(activeIndex.value);
  });

  nextTick(() => {
    if (optionsToDisplay.value.length === 0) searchTerm.value = "";
  });
};

/**
 * Unselects an option and removes it from the modelValue array.
 * It also sets the focus back to the search input
 * Then, it updates the UI in the next DOM update flush to scroll back to the element that was just removed.
 * @param {T} optionToRemove - The option to be unselected.
 */
const unselect = function (optionToRemove: T) {
  modelValue.value = remove(modelValue.value, optionToRemove, props.keyPath);
  focusOnInput();

  nextTick(() => {
    const nextActiveIndex = optionsToDisplay.value.findIndex((option) => option === optionToRemove);
    activeIndex.value = nextActiveIndex !== -1 ? nextActiveIndex : 0;

    scrollTo(activeIndex.value);
  });

  emit("onUnselect", optionToRemove);
};

const toggleSelect = function (option: T) {
  if (isin(modelValue.value, option, props.keyPath)) {
    if (props.isSelectedOptionRemovable) unselect(option);
  } else select(option);
};

const isSelected = function (option: T) {
  return isin(modelValue.value, option, props.keyPath);
};

const isActive = function (index: number) {
  return index === activeIndex.value;
};
</script>

<style lang="scss" scoped>
.Combobox {
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--gray-5);
  box-shadow: var(--shadow-3);
  height: fit-content;
}

.ComboboxViewport {
  --padding: 8px;
  overflow: auto;
  padding: var(--padding);
  max-height: calc(var(--combobox-content-available-height) - 2 * var(--padding) - 64px);
}

.ComboboxSearch {
  --padding-block: 8px;
  display: flex;
  padding-block: var(--padding-block);
  padding-inline: 8px;
  gap: 6px;
  min-height: calc(48px - 2 * var(--padding-block));
  border-bottom: 1px solid var(--gray-6);
  overflow: hidden;
}

.ComboboxSearchContent {
  --padding-block: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  min-height: calc(32px - 2 * var(--padding-block));
  overflow: hidden;
}
</style>
