<template>
  <div style="position: relative">
    <span ref="hide" class="HiddenInput"></span>
    <input ref="input" v-bind="$attrs" v-model="modelValue" :placeholder="placeholder" autofocus class="SearchInput" />
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from "vue";
import { useVModels } from "@vueuse/core";

// See solution proposed by Evan You: https://github.com/vuejs/core/issues/8286#issuecomment-1545659320
interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  placeholder?: string;
  autofocus?: boolean;
  modelValue?: string;
}

type Emits = {
  "update:modelValue": [value: string];
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { modelValue } = useVModels(props, emit);

const resizeInput = function () {
  if (hide.value && input.value) {
    hide.value.textContent = input.value.value ? input.value.value : input.value.placeholder;
    input.value.style.width = hide.value.offsetWidth + "px";
  }
};

const input = ref<HTMLInputElement>();
const hide = ref<HTMLSpanElement>();

onUpdated(() => {
  resizeInput();
});

defineExpose({ input });
</script>

<style scoped lang="scss">
.SearchInput {
  min-width: 60px;
  line-height: 24px;

  &::placeholder {
    color: var(--gray-4);
  }
}

.HiddenInput {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  white-space: pre;
}
</style>
