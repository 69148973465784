<template>
  <Icon :icon="mapIcon.remove" class="SelectTagRemove" />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.SelectTagRemove {
  color: var(--gray-2);
  transition: var(--transition-1);
  cursor: pointer;

  &:hover {
    color: var(--gray-1);
  }
}
</style>
